import UploadIcon from "../icons/UploadIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  createMoodboard,
  toggleImageStyle,
} from "../../features/slices/moodboardSlice";
import { AppDispatch, RootState } from "../../store/store";
import { ImageStyle } from "../../types/moodboardTypes";
import { changeColourPalleteOption } from "../../features/slices/moodboardSlice";

const MoodboardFilters = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { imageStyles, colourPalettes, activeColourPalette } = useSelector(
    (state: RootState) => state.moodboard
  );

  const handleColourPalleteChange = (event: any) => {
    dispatch(changeColourPalleteOption(parseInt(event.target.value)));
  };

  const handleImageStyleChange = (label: string) => {
    dispatch(toggleImageStyle(label));
  };

  const handleGenerateClick = () => {
    dispatch(createMoodboard());
    console.log("test");
  };

  return (
    <div className=" mt-10 p-6 border rounded-xl bg-white">
      <h2 className="text-md font-semibold text-gray-700">Filters</h2>
      <div className="flex flex-wrap gap-8 xl:block">
        {/* colour palette */}
        <div className="">
          <h3 className="mt-4 text-sm font-semibold text-gray-900">
            Colour palette
          </h3>
          <form className="mt-3 inline-block">
            {colourPalettes.map((palette: any) => {
              return (
                <div
                  key={palette.option}
                  className={`flex space-x-2 items-center  ${
                    activeColourPalette === palette.option ? "bg-gray-200" : ""
                  } rounded-lg p-2`}
                >
                  <input
                    className="appearance-none w-4 h-4 xl:w-5 xl:h-5 border-white border-[3px] border-transparent rounded-full cursor-pointer 
                  bg-white checked:bg-primary-800 focus:outline-none
                    ring-[1.5px] ring-primary-800  checked:ring-offset-primary-800"
                    type="radio"
                    name="option"
                    value={palette.option}
                    checked={activeColourPalette === palette.option}
                    onChange={handleColourPalleteChange}
                  />
                  {palette.colours.map((colour: string, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ backgroundColor: `#${colour}` }}
                        className={`w-5 h-5 xl:w-6 xl:h-6 rounded-lg border border-gray-300`}
                      ></div>
                    );
                  })}
                </div>
              );
            })}
          </form>
          <h3 className="mt-3 text-gray-700 font-semibold text-xs underline">
            Add a custom colour palette
          </h3>
        </div>

        {/* images style */}
        <div className="">
          <h3 className="mt-4 xl:mt-6 text-sm font-semibold text-gray-900">
            Images style
          </h3>
          <form className="mt-3 flex gap-2">
            {imageStyles.map((style: ImageStyle, index: number) => {
              return (
                <div key={index}>
                  <img
                    src={style.image}
                    alt={style.label}
                    className="h-[77px] xl:h-[90px]"
                  />
                  <div className="flex space-x-2 mt-2 ml-1 items-center">
                    <input
                      className="mt-[2px] appearance-none w-4 h-4 xl:w-5 xl:h-5 border-white border-[3px] border-transparent rounded-md cursor-pointer 
                    bg-white checked:bg-primary-800 focus:outline-none
                      ring-[1.5px] ring-primary-800  checked:ring-offset-primary-800"
                      type="checkbox"
                      name=""
                      id=""
                      onChange={() => handleImageStyleChange(style.label)}
                    />
                    <div className="text-xs text-grey-500">{style.label}</div>
                  </div>
                </div>
              );
            })}
          </form>
          <h3 className="mt-4 text-gray-700 font-semibold text-xs underline">
            See more options
          </h3>
        </div>

        {/* image upload */}
        <div className="">
          <h3 className="mt-4 xl:mt-6 text-sm font-semibold text-gray-900">
            Add your own images
          </h3>
          <div className="flex flex-col items-center  mt-4 py-5 xl:py-6 px-12 xl:px-0 border rounded-xl bg-[#fff] hover:bg-white cursor-pointer">
            <div className="bg-gray-100 rounded-full p-2 outline outline-8 outline-[#F9FAFB]">
              <UploadIcon />
            </div>
            <div className="mt-4 text-sm">
              <span className="text-primary-800 font-semibold mr-1">
                Click to upload
              </span>
              or drag and drop
            </div>
            <div className="mt-1 text-[10px] text-gray-600">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </div>
          </div>
        </div>
      </div>

      {/* generate moodboard */}
      <button
        onClick={handleGenerateClick}
        className="w-full max-w-[885px] mt-6 font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white shrink-0"
      >
        Generate moodboard
      </button>
    </div>
  );
};
export default MoodboardFilters;

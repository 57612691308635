import { useDispatch, useSelector } from "react-redux";
import { setActiveSubTab } from "../features/slices/navigationSlice";
import { useNavigate } from "react-router-dom";

const SecondaryNav: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tabs, activeTab, activeSubTab } = useSelector(
    (state: any) => state.navigation
  );

  const activeTabData = tabs.find((tab: any) => tab.id === activeTab) || {
    subTab: [],
  };

  if (activeTab === "home") {
    return null;
  }

  const handleSubTabClick = (id: string) => {
    dispatch(setActiveSubTab(id));
    navigate(`/${activeTab}/${id}`);
  };

  return (
    <section className="py-4 2xl:border-b 2xl:mb-10">
      <div className="mx-auto w-[90%] max-w-screen-3xl">
        <div className="flex items-center text-sm  font-medium hover:cursor-pointer">
          {activeTabData &&
            activeTabData.subTab.map((tab: any) => (
              <div
                key={tab.id}
                className={`px-2 py-2 rounded mx-[2px] md:mx-1 ${
                  activeSubTab === tab.id ? "bg-gray-100 font-semibold" : ""
                }`}
                onClick={() => handleSubTabClick(tab.id)}
              >
                {tab.label}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
export default SecondaryNav;

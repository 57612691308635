import { useDispatch } from "react-redux";
import { toggleConfirmationDialog } from "../../features/slices/projectSlice";
import { createCostume } from "../../features/slices/costumeSlice";
import { AppDispatch } from "../../store/store";

const RegenerateConfirmationDialog = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleRegenerate = () => {
    dispatch(createCostume());
    dispatch(toggleConfirmationDialog());
  };

  const handleClose = () => {
    dispatch(toggleConfirmationDialog());
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
          <div className="md:flex items-center">
            <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
              <i className="bx bx-error text-3xl">&#9888;</i>
            </div>
            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
              <p className="font-bold">Warning!</p>
              <p className="text-sm text-gray-700 mt-1">
                This action will regenerate your costume. Current costume will
                be lost.
              </p>
            </div>
          </div>
          <div className="flex items-center mt-4 justify-end">
            <button
              onClick={handleClose}
              className="px-4 py-3 bg-gray-200 text-black rounded-lg font-semibold text-sm md:order-1"
            >
              Cancel
            </button>
            <button
              onClick={handleRegenerate}
              className="ml-2 px-4 py-3 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:order-2"
            >
              Regenerate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegenerateConfirmationDialog;

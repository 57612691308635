import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "../features/slices/navigationSlice";
import costumeReducer from "../features/slices/costumeSlice";
import moodboardReducer from "../features/slices/moodboardSlice";
import projectReducer from "../features/slices/projectSlice";

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    costumes: costumeReducer,
    moodboard: moodboardReducer,
    project: projectReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

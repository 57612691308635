const TxtIcon = () => {
  return (
    <svg
      style={{
        width: "1em",
        height: "1em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M137.90246 0.00041a48.573421 48.573421 0 0 0-35.589106 15.293433A53.964778 53.964778 0 0 0 87.0404 50.934149V968.345622a48.706541 48.706541 0 0 0 15.272954 35.640306 49.97118 49.97118 0 0 0 35.589106 15.293434h746.336982a48.639981 48.639981 0 0 0 35.589105-15.293434 50.37054 50.37054 0 0 0 15.272954-35.640306V288.717094L646.727857 0.00041H137.90246z"
        fill="#E5E5E5"
      />
      <path
        d="M935.101501 288.717094h-237.445025c-27.822069-0.6656-50.22718-23.075831-50.928619-50.93374V0.00041l288.373644 288.716684z"
        fill="#CCCCCC"
      />
      <path
        d="M248.125776 365.184264h220.518312a25.51807 25.51807 0 0 0 24.19199-25.49759 25.51807 25.51807 0 0 0-24.19711-25.50271H248.125776a25.51807 25.51807 0 0 0-24.19711 25.49759 25.51807 25.51807 0 0 0 24.19711 25.49759z m0 169.825212h525.82379a25.44639 25.44639 0 0 0 25.431029-25.46687 25.44639 25.44639 0 0 0-25.431029-25.46687h-525.82379a25.44639 25.44639 0 0 0-25.43103 25.46687 25.44639 25.44639 0 0 0 25.43103 25.46687z m525.82379 118.886352h-525.82379a25.51807 25.51807 0 0 0-24.19711 25.49759 25.51807 25.51807 0 0 0 24.19711 25.50271h525.82379a25.51807 25.51807 0 0 0 24.19711-25.49759 25.51807 25.51807 0 0 0-24.19711-25.49759z"
        fill="#FFFFFF"
      />
    </svg>
  );
};
export default TxtIcon;

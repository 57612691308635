import React from "react";
import CostumeHeader from "../components/Costume/CostumeHeader";
import CostumeRequirements from "../components/Costume/CostumeRequirements";
import CostumePrompt from "../components/Costume/CostumePrompt";
import CostumePromptResults from "../components/Costume/CostumePromptResults";
import CostumeControls from "../components/Costume/CostumeControls";
import BottomFill from "../components/common/BottomFill";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const Costume: React.FC = () => {
  const { activeCharacter } = useSelector((state: RootState) => state.costumes);
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const characters = analysisResult.characters;
  const activeCharacterData = characters[activeCharacter];

  return (
    <>
      <div>
        <CostumeHeader />
        <CostumeRequirements />
        <CostumePrompt />
        <CostumePromptResults />
        {activeCharacterData.results && <CostumeControls />}
        <BottomFill />
      </div>
    </>
  );
};

export default Costume;

import { useDispatch, useSelector } from "react-redux";
import CloseIconLight from "../icons/CloseIconLight";
import {
  createCostume,
  setPromptContent,
} from "../../features/slices/costumeSlice";
import { useEffect, useRef, useState } from "react";
import { AppDispatch, RootState } from "../../store/store";

const CostumePrompt = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { promptContent, activeCharacter } = useSelector(
    (state: RootState) => state.costumes
  );
  const { analysisResult } = useSelector((state: RootState) => state.project);

  // const [localPromptContent, setLocalPromptContent] = useState(promptContent);
  const [localPromptContent, setLocalPromptContent] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const characters = analysisResult.characters;
  const activeCharacterData = characters[activeCharacter];

  const handleUsePrompt = (prompt: string) => {
    setLocalPromptContent(prompt);
    dispatch(setPromptContent({ characterId: activeCharacter, prompt }));
  };

  const handleGenerateCostume = () => {
    dispatch(setPromptContent({ characterId: activeCharacter, prompt: localPromptContent }));
    dispatch(createCostume());
  };

  // useEffect(() => {
  //   if (!promptContent && activeCharacterData) {
  //     setLocalPromptContent(activeCharacterData.costumePrompts[0]);
  //   }
  // }, [promptContent, activeCharacterData]);

  useEffect(() => {
    if (activeCharacterData && promptContent[activeCharacter] !== undefined) {
      setLocalPromptContent(promptContent[activeCharacter]);
    } else if (activeCharacterData) {
      setLocalPromptContent(activeCharacterData.costumePrompts[0]);
    }
  }, [promptContent, activeCharacter, activeCharacterData]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [localPromptContent]);

  return (
    <div className="mt-10 bg-white">
      <h1 className="text-md font-semibold text-gray-700">Prompt</h1>
      <div className="mt-2 rounded-[0.6rem] border bg-gradient-to-bl from-gradient-0 to-gradient-1 p-[1px] ">
        <div className="bg-white rounded-lg p-6 ">
          <div className="flex space-x-4 items-start justify-between">
            <textarea
              ref={textareaRef}
              onChange={(e) => setLocalPromptContent(e.target.value)}
              value={localPromptContent}
              style={{
                minHeight: "60px",
                overflow: "hidden",
              }}
              className="py-[10px] px-3 outline-none resize-none text-sm text-gray-600 flex-grow bg-white border border-gray-300 rounded-lg"
            />

            <button
              onClick={handleGenerateCostume}
              className="font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white shrink-0"
            >
              Generate costume
            </button>
          </div>
          <div className="mt-8">
            <h2 className="text-gray-500 text-xs font-medium">
              Prompt Suggestions based on
              <span className="ml-1 font-bold">{activeCharacterData.name}</span>
            </h2>
            <div className="flex mt-4 gap-6 items-start">
              {activeCharacterData.costumePrompts.map(
                (prompt: string, index: number) => (
                  <div
                    key={index + 1}
                    className="bg-gray-100 p-4 rounded-lg w-1/2"
                  >
                    <div className="flex justify-between">
                      <h1 className="text-gray-900 text-sm font-semibold">
                        Suggestion {index + 1}
                      </h1>
                      <CloseIconLight />
                    </div>
                    <div className="text-sm text-gray-600 mt-1">{prompt}</div>
                    <div
                      className="mt-4 font-serif font-semibold text-sm text-primary-800 cursor-pointer inline-block"
                      onClick={() => handleUsePrompt(prompt)}
                    >
                      Use this prompt
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostumePrompt;

const UploadIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16699 12.3333L9.50033 9M9.50033 9L12.8337 12.3333M9.50033 9V16.5M16.167 12.9524C17.1849 12.1117 17.8337 10.8399 17.8337 9.41667C17.8337 6.88536 15.7816 4.83333 13.2503 4.83333C13.0682 4.83333 12.8979 4.73833 12.8054 4.58145C11.7187 2.73736 9.71235 1.5 7.41699 1.5C3.96521 1.5 1.16699 4.29822 1.16699 7.75C1.16699 9.47175 1.8632 11.0309 2.98945 12.1613"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default UploadIcon;

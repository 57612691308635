import React from "react";
import CostumesMenu from "../components/Costume/CostumesMenu";
import Costume from "../pages/Costume";

const CostumesLayout: React.FC = () => {
  return (
    <div className="grid 2xl:grid-cols-[auto_minmax(0,_1fr)]">
      <div className="">
        <CostumesMenu />
      </div>
      <Costume />
    </div>
  );
};

export default CostumesLayout;

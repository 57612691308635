const BellIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79545 17.4998C7.38306 18.0185 8.15493 18.3332 9.0003 18.3332C9.84567 18.3332 10.6175 18.0185 11.2052 17.4998M14.0003 6.6665C14.0003 5.34042 13.4735 4.06865 12.5358 3.13097C11.5982 2.19329 10.3264 1.6665 9.0003 1.6665C7.67422 1.6665 6.40245 2.19329 5.46477 3.13097C4.52708 4.06865 4.0003 5.34042 4.0003 6.6665C4.0003 9.24166 3.35069 11.0048 2.62502 12.171C2.01291 13.1547 1.70685 13.6466 1.71807 13.7838C1.7305 13.9357 1.76268 13.9937 1.88511 14.0845C1.99568 14.1665 2.49413 14.1665 3.49101 14.1665H14.5096C15.5065 14.1665 16.0049 14.1665 16.1155 14.0845C16.2379 13.9937 16.2701 13.9357 16.2825 13.7838C16.2938 13.6466 15.9877 13.1547 15.3756 12.171C14.6499 11.0048 14.0003 9.24166 14.0003 6.6665Z"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default BellIcon;

import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "../icons/CloseIcon";
import PlusIconWhite from "../icons/PlusIconWhite";
import { RootState } from "../../store/store";

const CostumeHeader: React.FC = () => {
  const { activeCharacter } = useSelector((state: RootState) => state.costumes);
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const characters = analysisResult.characters;
  const activeCharacterData = characters[activeCharacter];

  return (
    <div className="flex justify-between items-center">
      <h1 className="text-lg tracking-1 font-semibold text-gray-900">
        {activeCharacterData.name}'s costume
      </h1>
      <div className="space-x-4 flex ">
        <button className="flex border space-x-1 text-md font-semibold border-gray-300 py-2 px-5 rounded-lg hover:bg-gray-300">
          <CloseIcon />
          <div>Cancel</div>
        </button>
        <button className="flex space-x-1 font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white tracking-1 ">
          <PlusIconWhite />
          <div>Create</div>
        </button>
      </div>
    </div>
  );
};

export default CostumeHeader;

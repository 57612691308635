import MoodboardFilters from "../components/Moodboard/MoodboardFilters";
import MoodboardScriptSynopsis from "../components/Moodboard/MoodboardScriptSynopsis";
import MoodboardResult from "../components/Moodboard/MoodboardResult";
import BottomFill from "../components/common/BottomFill";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Moodboard = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <h1 className="text-lg font-semibold text-gray-900">
        Moodboard generator
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-[1fr,auto] gap-4">
        <div className="xl:col-start-2">
          <MoodboardFilters />
        </div>
        <div className="xl:col-start-1 xl:row-start-1">
          <MoodboardScriptSynopsis />
          <MoodboardResult />
        </div>
        <BottomFill />
      </div>
    </div>
  );
};
export default Moodboard;

import React from "react";
import Logo from "../assets/logo.png";
import BellIcon from "../components/icons/BellIcon";
import SettingsIcon from "../components/icons/SettingsIcon";
import Avatar from "../assets/antonella-avatar.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveTab,
  setActiveSubTab,
  setActiveSubSubTab,
} from "../features/slices/navigationSlice";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { customComponents, customStyles } from "../types/reactSelectStyles";

const NavLayout: React.FC = () => {
  const { tabs, activeTab } = useSelector((state: any) => state.navigation);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabClick = (id: string) => {
    dispatch(setActiveTab(id));
    dispatch(setActiveSubTab(""));
    dispatch(setActiveSubSubTab(""));
    navigate(`/${id}`);
  };

  const handleLogoClick = () => {
    dispatch(setActiveTab("home"));
    dispatch(setActiveSubTab(""));
    dispatch(setActiveSubSubTab(""));
    navigate("/home");
  };

  const formattedOptions = tabs.map((tab: any) => ({
    value: tab.id,
    label: tab.label,
  }));

  const handleSelectChange = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    if (newValue) {
      handleTabClick(newValue.value);
    }
  };

  return (
    <nav className=" py-4 border-b">
      <div className="mx-auto w-[90%] max-w-screen-3xl  flex justify-between">
        <div className="flex">
          <div
            onClick={handleLogoClick}
            className="flex space-x-2 mr-6 items-center cursor-pointer"
          >
            <img className="w-8 xl:h-8" src={Logo} alt="Logo" />
            <div className="hidden sm:block text-display-xs font-medium">
              FilmTailor AI
            </div>
          </div>
          <Select
            className="lg:hidden nav-select"
            styles={customStyles}
            options={formattedOptions}
            value={formattedOptions.find(
              (option: any) => option.value === activeTab
            )}
            components={customComponents}
            onChange={handleSelectChange}
          />
          <div className="hidden lg:flex  items-center text-sm font-medium hover:cursor-pointer ">
            {tabs &&
              tabs.map((tab: any) => {
                return (
                  <div
                    key={tab.id}
                    className={`px-2 oveflow-x-hidden py-2 rounded mx-0 md:mx-[2px] 2xl:mx-1 ${
                      activeTab === tab.id ? "bg-gray-100 font-semibold " : ""
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    {tab.label}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex space-x-6 items-center">
          <SettingsIcon />
          <BellIcon />
          <img
            className="rounded-full w-10 h-10"
            src={Avatar}
            alt="Profile Avatar"
          />
        </div>
      </div>
    </nav>
  );
};

export default NavLayout;

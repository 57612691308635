import React from "react";
import LinkIcon from "../icons/LinkIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveCharacterForDialog,
  toggleCharacterDialog,
} from "../../features/slices/projectSlice";
import { RootState } from "../../store/store";
import {
  setActiveScene,
  toggleSceneDialog,
} from "../../features/slices/projectSlice";

const CostumeRequirements: React.FC = () => {
  const dispatch = useDispatch();
  const { activeCharacter } = useSelector((state: RootState) => state.costumes);
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const characters = analysisResult.characters;
  const activeCharacterData = characters[activeCharacter];

  const sceneDetails = activeCharacterData.scenes.map((sceneId: number) => {
    return analysisResult.scenes.find((scene: any) => scene.id === sceneId);
  });

  return (
    <>
      <div className="mt-8">
        {/* <h1 className="mb-2 text-gray-700 text-md font-semibold">
          Requirement
        </h1>
        <Select
          className="mt-4"
          styles={customStyles}
          options={formattedOptions}
          value={formattedOptions.find(
            (option) => option.value === activeCostumeOption
          )}
          components={customComponents}
          onChange={handleSelectChange}
        /> */}
      </div>
      <div className="mt-6 bg-white rounded-lg p-4">
        <h1 className="text-xs font-semibold text-gray-700">
          Requirement details
        </h1>
        <div className="flex flex-wrap mt-4 gap-4">
          <div>
            <h1 className="text-sm font-semibold text-gray-900">Character</h1>
            <div className="mt-4">
              <div
                onClick={() => {
                  dispatch(setActiveCharacterForDialog(activeCharacterData.id));
                  dispatch(toggleCharacterDialog());
                }}
                className="flex space-x-2 text-xs text-gray-700 font-medium font-serif bg-gray-100 px-3 py-2 rounded-2xl cursor-pointer"
              >
                <div>{activeCharacterData.name}</div>
                <LinkIcon />
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-sm font-semibold text-gray-900">Scenes</h1>
            <div className="flex flex-wrap mt-4 gap-4 font-serif text-xs text-gray-700 font-medium ">
              {sceneDetails.map((scene: any, index: number) => (
                <div
                  onClick={() => {
                    dispatch(setActiveScene(scene.id));
                    dispatch(toggleSceneDialog(scene.id));
                  }}
                  key={index}
                  className="flex space-x-2 bg-gray-100 px-3 py-2 rounded-2xl cursor-pointer"
                >
                  <div>
                    {scene.id}. {scene.name}
                  </div>
                  <LinkIcon />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostumeRequirements;

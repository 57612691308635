const tabsData = [
  { id: "home", label: "Home", subTab: [] },
  {
    id: "development",
    label: "Development",
    subTab: [
      {
        id: "dashboard",
        label: "Dashboard",
        subTab: [],
      },
      {
        id: "idea",
        label: "Idea & Concept",
        subTab: [
          { id: "brainstorming", label: "Brainstorming", subTab: [] },
          { id: "poc", label: "POC", subTab: [] },
          { id: "originalityscore", label: "Originality score", subTab: [] },
          { id: "ideasgenerator", label: "Ideas generator", subTab: [] },
          { id: "tips", label: "Tips", subTab: [] },
        ],
      },
      {
        id: "scriptwriting",
        label: "Scriptwriting",
        subTab: [
          { id: "scriptanalysis", label: "Script analysis", subTab: [] },
          { id: "proofreading", label: "Proof reading", subTab: [] },
          { id: "ratingscoring", label: "Rating & Scoring", subTab: [] },
          { id: "improvements", label: "Improvements", subTab: [] },
        ],
      },
      {
        id: "financing",
        label: "Financing",
        subTab: [
          { id: "grants", label: "Grants", subTab: [] },
          { id: "funding", label: "Funding", subTab: [] },
          { id: "investors", label: "Investors", subTab: [] },
          { id: "applications", label: "Applications", subTab: [] },
        ],
      },
      {
        id: "team",
        label: "Team",
        subTab: [
          { id: "teammatching", label: "Team matching", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
          { id: "imdb", label: "IMDb", subTab: [] },
        ],
      },
    ],
  },
  {
    id: "preproduction",
    label: "Pre-production",
    subTab: [
      {
        id: "dashboard",
        label: "Dashboard",
        subTab: [],
      },
      {
        id: "budgeting",
        label: "Budgeting",
        subTab: [
          { id: "budgetingtools", label: "Budgeting tools", subTab: [] },
        ],
      },
      {
        id: "locations",
        label: "Locations",
        subTab: [
          { id: "database", label: "Database", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
          { id: "locationmatching", label: "Location matching", subTab: [] },
        ],
      },
      {
        id: "casting",
        label: "Casting",
        subTab: [
          { id: "database", label: "Database", subTab: [] },
          { id: "castmatching", label: "Cast matching", subTab: [] },
          { id: "imdb", label: "IMDb", subTab: [] },
        ],
      },
      {
        id: "artdepartment",
        label: "Art department",
        subTab: [
          { id: "moodboard", label: "Moodboard", subTab: [] },
          { id: "costumes", label: "Costumes", subTab: [] },
          { id: "props", label: "Props", subTab: [] },
          { id: "set", label: "Set", subTab: [] },
        ],
      },
    ],
  },
  {
    id: "production",
    label: "Production",
    subTab: [
      {
        id: "dashboard",
        label: "Dashboard",
        subTab: [],
      },
      {
        id: "shooting",
        label: "Shooting",
        subTab: [
          { id: "scheduling", label: "Scheduling", subTab: [] },
          { id: "dailycoordination", label: "Daily coordination", subTab: [] },
          { id: "continuity", label: "Continuity", subTab: [] },
        ],
      },
      {
        id: "catering",
        label: "Catering",
        subTab: [
          { id: "database", label: "Database", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
        ],
      },
      {
        id: "accomodation",
        label: "Accomodation",
        subTab: [
          { id: "database", label: "Database", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
        ],
      },
      {
        id: "transport",
        label: "Transport",
        subTab: [
          { id: "maps", label: "Maps", subTab: [] },
          { id: "database", label: "Database", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
          { id: "itineraries", label: "Itineraries", subTab: [] },
        ],
      },
    ],
  },
  {
    id: "postproduction",
    label: "Post-production",
    subTab: [
      {
        id: "dashboard",
        label: "Dashboard",
        subTab: [],
      },
      {
        id: "editing",
        label: "Editing",
        subTab: [
          { id: "editing", label: "Editing", subTab: [] },
          { id: "sounddesign", label: "Sound design", subTab: [] },
          { id: "vx", label: "VX", subTab: [] },
          { id: "colourgrading", label: "Colour grading", subTab: [] },
        ],
      },
      {
        id: "distribution",
        label: "Distribution",
        subTab: [
          { id: "releasestrategy", label: "Release strategy", subTab: [] },
          { id: "database", label: "Database", subTab: [] },
          { id: "vx", label: "VX", subTab: [] },
          { id: "searchtools", label: "Search tools", subTab: [] },
        ],
      },
      {
        id: "marketing",
        label: "Marketing",
        subTab: [
          { id: "promomaterials", label: "Promo materials", subTab: [] },
          { id: "campaigns", label: "Campaigns", subTab: [] },
        ],
      },
      {
        id: "premiere",
        label: "Premiere",
        subTab: [
          {
            id: "festivalscinemas",
            label: "Festivals & Cinemas database",
            subTab: [],
          },
          { id: "searchtools", label: "Search tools", subTab: [] },
        ],
      },
    ],
  },
];

export default tabsData;

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveSubSubTab } from "../features/slices/navigationSlice";

const ThirdNavLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tabs, activeTab, activeSubTab, activeSubSubTab } = useSelector(
    (state: any) => state.navigation
  );

  const activeTabData = tabs.find((tab: any) => tab.id === activeTab) || {
    subTab: [],
  };
  const activeSubTabData = activeTabData.subTab.find(
    (subTab: any) => subTab.id === activeSubTab
  ) || { subTab: [] };

  const handleSubSubTabClick = (id: string) => {
    dispatch(setActiveSubSubTab(id));
    navigate(`/${activeTab}/${activeSubTab}/${id}`);
  };

  if (activeSubTab === "dashboard" || activeSubTab === "") {
    return null;
  }

  return (
    <div className="w-[91%] mx-auto 2xl:w-[100%] flex 2xl:flex-col py-4 2xl:mt-16 hover:cursor-pointer 2xl:max-w-[300px]">
      {activeSubTabData.subTab.map((tab: any) => (
        <div
          className={`px-4 py-2 text-sm font-medium 2xl:text-md rounded mx-[2px] md:mx-1 ${
            activeSubSubTab === tab.id ? "bg-gray-200 font-semibold" : ""
          }`}
          key={tab.id}
          onClick={() => handleSubSubTabClick(tab.id)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
export default ThirdNavLayout;

import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ExportIcon from "../icons/ExportIcon";
import SaveIcon from "../icons/SaveIcon";
import { ClimbingBoxLoader } from "react-spinners";

const MoodboardResult = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.moodboard.isLoading
  );
  const moodboardUrl = useSelector(
    (state: RootState) => state.moodboard.moodboardUrl
  );

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="flex justify-center xl:block mt-8">
      <div className="max-w-[800px] xl:max-w-[1000px]">
        <div className="align-top rounded-[0.65rem] border bg-gradient-to-bl from-gradient-0 to-gradient-1 p-[1px]">
          <div className="bg-white rounded-lg p-6 min-h-[700px]">
            {!moodboardUrl && !isLoading && (
              <div className="h-[700px] flex justify-center items-center text-gray-500">
                Please select the filters and click "Generate moodboard"
              </div>
            )}

            {isLoading && (
              <div className="h-[700px] flex flex-col justify-center items-center text-gray-500">
                <ClimbingBoxLoader color="#1e7b76" size={25} />
                <div className="mt-12 font-semibold text-xl">
                  Generating moodboard...
                </div>
              </div>
            )}

            {/* {!isLoading && moodboardUrl && !imageLoaded && (
              <div className="h-[700px] flex flex-col justify-center items-center text-gray-500">
                <ClimbingBoxLoader color="#1e7b76" size={25} />
              </div>
            )} */}

            {moodboardUrl && !isLoading && (
              <>
                <img
                  className="object-cover w-full"
                  src={moodboardUrl}
                  alt="Moodboard"
                  onLoad={handleImageLoad}
                  style={{ display: imageLoaded ? "block" : "none" }}
                />
                {imageLoaded && (
                  <div className="flex flex-wrap gap-2 justify-end mt-4">
                    <button className="flex border space-x-2 text-sm xl:text-md font-semibold border-gray-300 py-2 px-3 xl:px-5 rounded-lg hover:bg-gray-300 items-center">
                      <ExportIcon />
                      <div>Export</div>
                    </button>
                    <button className="flex space-x-2 font-semibold text-sm xl:text-md bg-primary-800 py-2 px-3 xl:px-5 rounded-lg text-white items-center">
                      <SaveIcon />
                      <div>Save mood board</div>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodboardResult;

import ThirdNavLayout from "./ThirdNavLayout";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="grid 2xl:grid-cols-[auto_minmax(0,_1fr)] 2xl:w-[90%] 2xl:mx-auto gap-10 max-w-screen-3xl ">
      <div className="bg-gray-100 2xl:bg-[#fff] ">
        <ThirdNavLayout />
      </div>
      <div className="w-[90%] mx-auto 2xl:w-[100%]">{children}</div>
    </div>
  );
};
export default MainLayout;

import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavLayout from "./layouts/NavLayout";
import SecondaryNav from "./layouts/SecondaryNavLayout";
import CostumesLayout from "./layouts/CostumesLayout";
import MainLayout from "./layouts/MainLayout";
import Moodboard from "./pages/Moodboard";
import Home from "./pages/Home";
import {
  setActiveTab,
  setActiveSubTab,
  setActiveSubSubTab,
  setAuthenticated,
} from "./features/slices/navigationSlice";
import CharacterDialog from "./components/NewProject/CharacterDialog";
import NewProject from "./pages/NewProject";
import Login from "./pages/Login";
import StepperNavLayout from "./layouts/StepperNavLayout";
import SceneDialog from "./components/NewProject/SceneDialog";
import RegenerateConfirmationDialog from "./components/NewProject/RegenerateConfirmationDialog";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Cookies from "js-cookie";
import { RootState } from "./store/store";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(
    (state: RootState) => state.navigation
  );

  const {
    isCharacterDialogVisible,
    isConfirmationDialogVisible,
    isSceneDialogVisible,
  } = useSelector((state: RootState) => state.project);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get("token");
    const checkAuth = async () => {
      if (token) {
        dispatch(setAuthenticated(true));
      } else {
        dispatch(setAuthenticated(false));
      }
      setLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !loading) {
      const currentPath = location.pathname;
      if (currentPath.includes("preProduction")) {
        const correctedPath = currentPath.replace(
          /preProduction/g,
          "preproduction"
        );
        navigate(correctedPath, { replace: true });
        return;
      }

      const pathSegments = location.pathname.split("/").filter(Boolean);
      dispatch(setActiveTab(pathSegments[0] || "home"));
      dispatch(setActiveSubTab(pathSegments[1] || ""));
      dispatch(setActiveSubSubTab(pathSegments[2] || ""));
    }
  }, [location.pathname, isAuthenticated, loading, dispatch, navigate]);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      if (location.pathname === "/login" || location.pathname === "/") {
        navigate("/home");
      }
    } else if (!loading && !isAuthenticated) {
      if (location.pathname !== "/login") {
        navigate("/login");
      }
    }
  }, [isAuthenticated, loading, location.pathname, navigate]);

  const isProjectPage = location.pathname.includes("/project");

  if (loading) {
    return null;
  }

  return (
    <div className="app-container relative">
      {isAuthenticated ? (
        <>
          {(isCharacterDialogVisible ||
            isSceneDialogVisible ||
            isConfirmationDialogVisible) && (
            <div className="fixed w-[100vw] h-[100vh] inset-0 bg-black opacity-50 z-10"></div>
          )}
          {isCharacterDialogVisible && (
            <div className="fixed w-full h-full flex justify-center items-center z-50">
              <CharacterDialog />
            </div>
          )}
          {isConfirmationDialogVisible && (
            <div className="fixed w-full h-full flex justify-center items-center z-50">
              <RegenerateConfirmationDialog />
            </div>
          )}
          {isSceneDialogVisible && (
            <div className="fixed w-full h-full flex justify-center items-center z-50">
              <SceneDialog />
            </div>
          )}
          <NavLayout />
          {isProjectPage ? <StepperNavLayout /> : <SecondaryNav />}
          <MainLayout>
            <Routes>
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project"
                element={
                  <ProtectedRoute>
                    <NewProject />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/preproduction/artdepartment/costumes"
                element={
                  <ProtectedRoute>
                    <CostumesLayout />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/preproduction/artdepartment/moodboard"
                element={
                  <ProtectedRoute>
                    <Moodboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </MainLayout>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </div>
  );
};

export default App;

import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const MoodboardScriptSynopsis = () => {
  const { analysisResult } = useSelector((state: RootState) => state.project);

  return (
    <div className="mt-10 p-4 border rounded-xl">
      <h2 className="text-md font-semibold text-gray-700">Script Synopsis</h2>
      <p className="mt-4 text-sm font-gray-600">
        {analysisResult.scriptSynopsis.shortSynopsis}
      </p>
    </div>
  );
};
export default MoodboardScriptSynopsis;

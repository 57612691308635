import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ProjectState } from "../../types/projectTypes";
import customFetch from "../../routes/util";
import { RootState } from "../../store/store";

export const createScriptAnalysis = createAsyncThunk(
  "project/getScriptAnalysisRequest",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { fileName } = state.project;

    const response = await customFetch(
      `/api/openai/script?filename=${fileName}`,
      {
        headers: { accept: "application/json" },
      }
    );

    if (response.statusCode === 200) {
      return response.data;
    } else {
      return rejectWithValue(
        `Failed to generate script analysis. Status code: ${response.statusCode}, message: ${response.message}`
      );
    }
  }
);

const initialState: ProjectState = {
  isLoading: false,
  isSceneDialogVisible: false,
  isCharacterDialogVisible: false,
  isConfirmationDialogVisible: false,
  error: null,
  fileName: "",
  analysisResult: null,
  activeScene: null,
  activeCharacterForDialog: null,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    toggleSceneDialog: (state) => {
      state.isSceneDialogVisible = !state.isSceneDialogVisible;
    },
    setActiveScene: (state, action: PayloadAction<number>) => {
      state.activeScene = action.payload;
    },
    toggleCharacterDialog: (state) => {
      state.isCharacterDialogVisible = !state.isCharacterDialogVisible;
    },
    toggleConfirmationDialog: (state) => {
      state.isConfirmationDialogVisible = !state.isConfirmationDialogVisible;
    },
    setActiveCharacterForDialog: (state, action: PayloadAction<number>) => {
      state.activeCharacterForDialog = action.payload;
    },
    resetProjectState: (state: any) => {
      state.fileName = "";
      state.analysisResult = null;
      state.activeScene = null;
      state.activeCharacterForDialog = null;
      state.isSceneDialogVisible = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createScriptAnalysis.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createScriptAnalysis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.analysisResult = action.payload;
      })
      .addCase(createScriptAnalysis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to analyze script";
      });
  },
});

export const {
  setFileName,
  toggleSceneDialog,
  setActiveScene,
  toggleCharacterDialog,
  toggleConfirmationDialog,
  resetProjectState,
  setActiveCharacterForDialog,
} = projectSlice.actions;
export default projectSlice.reducer;

const NoIndicatorSeparator = () => null;

const customComponents = {
  IndicatorSeparator: NoIndicatorSeparator,
};

export const customStyles = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    minHeight: "48px",
    backgroundColor: "white",
    borderRadius: "0.5rem",
    borderColor: state.isFocused ? "#D0D5DD" : "#D0D5DD",
    boxShadow: state.isFocused ? "" : "",

    "&:hover": {
      borderColor: state.isFocused ? "" : "",
    },
    ".nav-select &": {
      backgroundColor: "#F2F4F7",
      border: "none",
      minWidth: "175px",
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "0 20px",
    ".nav-select &": {
      backgroundColor: "#F2F4F7",
    },
  }),
  input: (base: any) => ({
    ...base,
    margin: "0px",
  }),
  option: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    backgroundColor: isFocused ? "#F2F4F7" : isSelected ? "" : null,

    color: "#101828",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    ":active": {
      backgroundColor: !isSelected && "#F2F4F7",
    },
    padding: "10px 20px",
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow: "",
    border: "1px solid #D0D5DD",
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: "0.5rem",
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#aab7c4",
    fontSize: "1rem",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "#101828",
    fontSize: "14px",
    fontWeight: "500",
    ".nav-select &": {
      fontWeight: "600",
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#667085",
    padding: "0 14px",
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: "#999",
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
  }),
};

export { customComponents };

import CloseGreenIcon from "../components/icons/CloseGreenIcon";
import ClosePurpleIcon from "../components/icons/ClosePurpleIcon";
import MoreFiltersIcon from "../components/icons/MoreFiltersIcon";
import PlusIconWhite from "../components/icons/PlusIconWhite";
import SearchIcon from "../components/icons/SearchIcon";
import ProjectImage1 from "../assets/project-1.jpg";
import ProjectImage2 from "../assets/project-2.jpg";
import ProjectImage3 from "../assets/project-3.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
	const navigate = useNavigate();

	const handleNewProjectClick = () => {
		navigate("/project");
	};

	return (
		<section className="mx-auto w-[90vw] max-w-screen-3xl">
			{/* header */}
			<header className="mt-12 flex justify-between">
				<div>
					<h1 className=" font-semibold text-display-sm">
						Welcome back, Antonella
					</h1>
					<p className="mt-1 text-gray-600">
						Track, manage and forecast your projects
					</p>
				</div>
				<div>
					<button
						onClick={handleNewProjectClick}
						className="flex space-x-1 text-sm font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white tracking-1 "
					>
						<PlusIconWhite />
						<div>New project</div>
					</button>
				</div>
			</header>

			{/* controls */}
			<div className="mt-12 flex justify-between items-center">
				<div className="flex gap-3">
					<button className="flex border items-center space-x-1 text-sm font-semibold text-[#2B5314] bg-[#E3FBCC] py-2 px-5 rounded-lg ">
						<div>Active</div>
						<CloseGreenIcon />
					</button>
					<button className="flex border items-center space-x-1 text-sm text-[#6941C6] font-semibold bg-[#F9F5FF] py-2 px-5 rounded-lg ">
						<div>London</div>
						<ClosePurpleIcon />
					</button>
					<button className="flex border items-center space-x-2 text-sm font-semibold border-gray-300 py-2 px-5 rounded-lg hover:bg-gray-100">
						<div>More filters</div>
						<MoreFiltersIcon />
					</button>
				</div>
				<form action="" className="h-full relative">
					<input
						placeholder="Search"
						className="text-gray-500 text-md px-[2.3rem] py-2 border border-gray-300 rounded-lg outline-none"
					/>
					<div className="absolute left-[12px] top-[12px] text-gray-500 text-md font-serif pointer-events-none">
						<SearchIcon />
					</div>
				</form>
			</div>

			{/* projects */}
			<div className="mt-8 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
				{/* project 1 */}
				<div>
					<img className="w-full" src={ProjectImage1} alt="" />

					<h2 className="mt-6 font-semibold text-display-xs">Frozen Summit</h2>
					<p className="mt-2 text-gray-600">
						A group of mountaineers faces extreme challenges and self-discovery
						as they attempt to conquer the perilous, snow-covered Snowpeak
						Mountain.
					</p>
					<div className="mt-4 flex space-x-2">
						<div className="px-[12px] py-[2px]  font-semibold rounded-lg text-[#2B5314] bg-[#E3FBCC]">
							Active
						</div>
						<div className="px-[12px] py-[2px]  font-semibold rounded-lg text-[#6941C6] bg-[#F9F5FF]">
							London
						</div>
					</div>
				</div>

				{/* project 2 */}
				<div>
					<img className="w-full" src={ProjectImage2} alt="" />

					<h2 className="mt-6 font-semibold text-display-xs">White Leopard</h2>
					<p className="mt-2 text-gray-600">
						In the heart of the Himalayas, a wildlife photographer's quest to
						capture the elusive white leopard turns into a thrilling adventure
						of survival and unexpected friendship.
					</p>
					<div className="mt-4 flex space-x-2">
						<div className="px-[12px] py-[2px]  font-semibold rounded-lg text-[#2B5314] bg-[#E3FBCC]">
							Active
						</div>
						<div className="px-[12px] py-[2px]  font-semibold rounded-lg text-[#6941C6] bg-[#F9F5FF]">
							London
						</div>
					</div>
				</div>

				{/* project 3 */}
				<div>
					<img className="w-full" src={ProjectImage3} alt="" />

					<h2 className="mt-6 font-semibold text-display-xs">
						Shadow of the Sith
					</h2>
					<p className="mt-2 text-gray-600">
						In the depths of a dark cave, a rogue Sith with a red lightsaber
						faces their ultimate test against ancient forces and inner demons in
						a battle for power and redemption.
					</p>
					<div className="mt-4 flex space-x-2">
						<div className="px-[12px] py-[2px] font-semibold rounded-lg text-[#2B5314] bg-[#E3FBCC]">
							Active
						</div>
						<div className="px-[12px] py-[2px]  font-semibold rounded-lg text-[#6941C6] bg-[#F9F5FF]">
							London
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Home;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import tabsData from "../../routes/tabsData";
import { NavigationState } from "../../types/navigationTypes";
import customFetch from "../../routes/util";
import Cookies from "js-cookie";

export const login = createAsyncThunk(
  "navigation/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    const payload = JSON.stringify({ email, password });
    const response = await customFetch("/api/authentication/login", {
      method: "POST",
      body: payload,
      headers: {},
    });

    if (response.statusCode === 200) {
      Cookies.set("token", response.data, {
        expires: 7,
        secure: true,
      });
    } else if (response.statusCode === 401) {
      return rejectWithValue(response.message);
    } else {
      return rejectWithValue(response.message);
    }
  }
);

const initialState: NavigationState = {
  tabs: tabsData || [],
  activeTab: "home",
  activeSubTab: "",
  activeSubSubTab: "",
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setActiveSubTab: (state, action: PayloadAction<string>) => {
      state.activeSubTab = action.payload;
    },
    setActiveSubSubTab: (state, action: PayloadAction<string>) => {
      state.activeSubSubTab = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  setActiveTab,
  setActiveSubTab,
  setActiveSubSubTab,
  setAuthenticated,
} = navigationSlice.actions;
export default navigationSlice.reducer;

import { useState, useEffect } from "react";
import FileUpload from "../components/NewProject/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
  createScriptAnalysis,
  setActiveScene,
  setFileName,
  toggleSceneDialog,
  toggleCharacterDialog,
  setActiveCharacterForDialog,
  resetProjectState,
} from "../features/slices/projectSlice";
import { ClimbingBoxLoader } from "react-spinners";
import LinkIcon from "../components/icons/LinkIcon";
import { useNavigate, useLocation } from "react-router-dom";

export type UploadedFile = {
  name: string;
  size: string;
  type: string;
};

const NewProject = () => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const [showScriptUpload, setShowScriptUpload] = useState<boolean>(false);
  const [isUpLoading, setIsUpLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { fileName, analysisResult, isLoading } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (uploadedFile) {
      dispatch(setFileName(uploadedFile.name));
    }
  }, [uploadedFile, dispatch]);

  useEffect(() => {
    if (!projectName) {
      dispatch(resetProjectState());
      dispatch(setFileName(""));
      setProjectName("");
      setUploadedFile(null);
      setIsFileUploaded(false);
      setShowScriptUpload(false);
      setIsUpLoading(false);
    }
  }, [projectName, dispatch]);

  useEffect(() => {
    if (!isLoading && analysisResult) {
      window.scrollBy({
        top: -460,
        behavior: "smooth",
      });
    }
  }, [isLoading, analysisResult]);

  const handleGenerate = () => {
    if (fileName) {
      dispatch(createScriptAnalysis());
      window.scrollBy({ top: 500, behavior: "smooth" });
    }
  };

  const handleNext = () => {
    setShowScriptUpload(true);
    window.scrollBy({ top: 625, behavior: "smooth" });
  };

  const navigateToMoodboard = () => {
    navigate("/preproduction/artdepartment/moodboard");
  };

  return (
    <section className="max-w-screen-3xl mx-auto w-[90vw]">
      {/* script breakdown */}
      <div className="mt-12">
        <h1 className="text-xl font-semibold text-gray-700">
          Create a new project
        </h1>
        <p className="mt-6 max-w-[850px] text-sm text-gray-700">
          Follow the next steps to create a new project by providing your
          script, and see how FilmTailor AI breaks it down in no time!
        </p>
      </div>

      {/* project name */}
      <div className="flex flex-col space-y-10 mt-[100px]">
        <header>
          <div className="flex items-center gap-3">
            <div className="bg-[#CFFFFC] rounded-full h-8 w-8 flex items-center justify-center text-primary-800 font-semibold">
              1
            </div>
            <div className="text-lg font-semibold text-gray-600">
              Project name
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-700">
            Provide a name for your project to get started
          </p>
        </header>
        <div className="flex justify-center items-center ml-24">
          <input
            className="align-bottom mr-4 border text-md border-gray-300 rounded-lg outline-none py-2 px-4 w-[500px]"
            placeholder="e.g. Frozen Summit"
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />

          {projectName ? (
            <button
              onClick={handleNext}
              className="block font-semibold bg-primary-800 py-2 px-16 rounded-lg text-white"
            >
              Next
            </button>
          ) : (
            <button className="block border text-md font-semibold border-gray-300 py-2 px-16 rounded-lg ">
              Next
            </button>
          )}
        </div>
      </div>

      {showScriptUpload && (
        <>
          <div className="mt-24  border-b"></div>
          <div id="scriptUploadSection" className="mt-24">
            <header>
              <div className="flex items-center gap-3">
                <div className="bg-[#CFFFFC] rounded-full h-8 w-8 flex items-center justify-center text-primary-800 font-semibold">
                  2
                </div>
                <div className="text-lg font-semibold text-gray-600">
                  Script upload
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-700">
                Upload your script to begin the analysis
              </p>
            </header>

            <div className="mt-12 flex-col lg:flex-row flex gap-12">
              <FileUpload
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                isUpLoading={isUpLoading}
                setIsUpLoading={setIsUpLoading}
                setIsFileUploaded={setIsFileUploaded}
              />
              <div className="flex flex-col items-stretch w-full lg:w-[600px]">
                <h2 className="text-md font-semibold text-gray-600">
                  What will you get?
                </h2>
                <ol className="mt-4 space-y-4 text-sm text-gray-700">
                  <li>
                    A breakdown with detailed analysis of characters, scenes,
                    and required costumes.
                  </li>
                </ol>
                {isFileUploaded ? (
                  <button
                    onClick={handleGenerate}
                    className="mt-6 font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white"
                  >
                    Generate
                  </button>
                ) : (
                  <button className="mt-6 border text-md font-semibold border-gray-300 py-2 px-5 rounded-lg ">
                    Generate
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {isLoading ? (
        <div className="flex flex-col justify-center items-center h-[50vh]">
          <div className="mt-[350px] h-[700px] flex flex-col justify-center items-center text-gray-500">
            <ClimbingBoxLoader color="#1e7b76" size={25} />
            <div className="mt-12 font-semibold text-xl">
              Generating script analysis...
            </div>
          </div>
        </div>
      ) : (
        analysisResult && (
          <>
            <div className="mt-24 border-b"></div>
            <div className="mt-24">
              <header>
                <div className="flex items-center gap-3">
                  <div className="bg-[#CFFFFC] rounded-full h-8 w-8 flex items-center justify-center text-primary-800 font-semibold">
                    3
                  </div>
                  <div className="text-lg font-semibold text-gray-600">
                    Script Analysis
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-700">
                  Review the AI analysis before proceeding
                </p>
              </header>
              <div className="flex mt-8">
                <div className="w-1/3">
                  <div className="bg-gray-100 p-4 rounded-xl ">
                    <h3 className="text-md font-semibold text-gray-[#101828] bg-gray-200 px-4 py-3 rounded-xl">
                      Characters
                    </h3>
                    <ul className="mt-2 max-h-[250px] pr-4 overflow-y-auto">
                      {analysisResult.characters?.map(
                        (character: any, index: number) => (
                          <div
                            key={index}
                            onClick={() => {
                              dispatch(
                                setActiveCharacterForDialog(character.id)
                              );
                              dispatch(toggleCharacterDialog());
                            }}
                            className="mt-2 flex justify-between items-center bg-white px-4 py-3 rounded-2xl cursor-pointer text-sm text-[#344054] font-semibold"
                          >
                            <div>
                              {character.id}. {character.name}
                            </div>
                            <LinkIcon />
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="mt-6 bg-gray-100 p-4 rounded-xl">
                    <h3 className="text-md font-semibold text-gray-[#101828] bg-gray-200 px-4 py-3 rounded-xl">
                      Scenes
                    </h3>
                    <ul className="mt-4 max-h-[250px] pr-4 overflow-y-auto">
                      {analysisResult.scenes.map(
                        (scene: any, index: number) => (
                          <div
                            onClick={() => {
                              dispatch(setActiveScene(scene.id));
                              dispatch(toggleSceneDialog());
                            }}
                            key={index}
                            className="mt-2 flex justify-between items-center bg-white px-4 py-3 rounded-2xl cursor-pointer text-sm text-[#344054] font-semibold"
                          >
                            <div>
                              {scene.id}. {scene.name}
                            </div>
                            <LinkIcon />
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="w-2/3 pl-8">
                  <h3 className="text-lg text-center font-semibold text-[#101828]">
                    Script Synopsis
                  </h3>
                  <div className="mt-4 text-md text-gray-[#475467]">
                    {analysisResult.scriptSynopsis.paragraphs.map(
                      (paragraph: string, index: number) => (
                        <p key={index} className="mt-6">
                          {paragraph}
                        </p>
                      )
                    )}
                  </div>
                  <div className="flex justify-end mt-[200px]">
                    <button
                      onClick={navigateToMoodboard}
                      className="bg-primary-800 text-white font-semibold  py-2 px-[100px] rounded-lg"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}

      {/* fill */}
      <div className="h-[100vw]"></div>
    </section>
  );
};
export default NewProject;

const DocxIcon = () => {
  return (
    <svg
      style={{
        width: "1em",
        height: "1em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M594.944 0l335.12448 341.31968v563.2c0 65.9968-52.50048 119.48032-117.29408 119.48032H209.54624c-64.7936 0-117.2992-53.5296-117.2992-119.48032V119.48032C92.25216 53.48352 144.75776 0 209.55136 0H594.944z"
        fill="#5895FF"
      />
      <path
        d="M930.06848 341.31968h-211.9168c-64.74752 0-123.20768-59.48928-123.20768-125.4912V0l335.12448 341.31968z"
        fill="#FFFFFF"
        fill-opacity=".4"
      />
      <path
        d="M427.37664 725.31968V768H259.8144v-42.68032h167.56224zM594.944 640v42.68032H259.8144V640H594.944z m0-85.31968v42.63936H259.8144v-42.63936H594.944z m0-85.36064V512H259.8144v-42.68032H594.944z"
        fill="#FFFFFF"
      />
    </svg>
  );
};
export default DocxIcon;

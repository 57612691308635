import AbstractImage from "../assets/image-style-abstract.jpg";
import RealisticImage from "../assets/image-style-realistic.jpg";
import IllustrationImage from "../assets/iamge-style-illustration.jpg";

export const imageStyles = [
  {
    image: AbstractImage,
    label: "Abstract",
  },
  {
    image: RealisticImage,
    label: "Realistic",
  },
  {
    image: IllustrationImage,
    label: "Illustration",
  },
];

export const colourPalettes = [
  {
    option: 0,
    colours: [
      "D8E2DC",
      "FFE5D9",
      "FFCAD4",
      "F4ACB7",
      "9D8189",
      "6B4E71",
      "355070",
    ],
  },
  {
    option: 1,
    colours: [
      "F0EFEB",
      "D6CCC2",
      "A5A58D",
      "6B705C",
      "CB997E",
      "DD6E42",
      "987284",
    ],
  },
  {
    option: 2,
    colours: [
      "E8F0F2",
      "BBE1FA",
      "3282B8",
      "0F4C75",
      "1B262C",
      "D1E8E2",
      "FFC8A2",
    ],
  },
];

const PdfIcon = () => {
  return (
    <svg
      style={{
        width: "1em",
        height: "1em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden",
      }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M594.944 0l335.12448 341.31968v563.2c0 65.9968-52.50048 119.48032-117.29408 119.48032H209.54624c-64.7936 0-117.2992-53.5296-117.2992-119.48032V119.48032C92.25216 53.48352 144.75776 0 209.55136 0H594.944z"
        fill="#E94848"
      />
      <path
        d="M482.95424 375.48032a40.58624 40.58624 0 0 0-40.02816 40.77568c0 27.78624 15.17056 62.32576 31.1808 94.7712-12.56448 39.8336-26.71616 82.47296-44.82048 118.5024-37.0944 14.7968-70.1952 25.8304-90.0608 42.16832a41.70752 41.70752 0 0 0-12.3904 29.9264c0 22.34368 18.06336 40.77568 40.0384 40.77568a39.33184 39.33184 0 0 0 29.27104-12.47232c14.6176-17.82784 31.88736-50.12992 47.29344-79.6416 35.42016-14.19776 72.60672-28.672 108.44672-37.3248 26.1632 21.4528 64.0512 35.65056 95.18592 35.65056 21.96992 0 40.03328-18.38592 40.03328-40.77568a40.58624 40.58624 0 0 0-40.03328-40.72448c-24.99072 0-61.29664 9.07264-89.0368 18.61632a301.3376 301.3376 0 0 1-58.09152-76.98432c10.65984-33.3312 23.04-66.65728 23.04-92.48768a40.58624 40.58624 0 0 0-40.02816-40.77568z m0 24.43776c8.98048 0 16.01024 7.168 16.01024 16.29184 0 12.2368-6.42048 34.816-13.87008 59.01824C475.136 451.67616 466.944 429.056 466.944 416.256c0-9.1648 7.02464-16.29184 16.01024-16.29184v-0.04608z m6.8864 139.5456a323.57376 323.57376 0 0 0 41.5232 53.76c-23.74144 6.6048-46.91968 15.0784-69.82144 23.92064 11.07968-25.36448 19.9168-51.75808 28.29824-77.72672v0.04608z m157.2352 52.12672c8.98048 0 16.01024 7.12192 16.01024 16.29184 0 9.12384-7.02976 16.29184-16.01536 16.29184-18.05824 0-43.65824-8.28416-64.18432-19.87584 23.552-6.79424 49.2032-12.7488 64.18432-12.7488v0.04096zM408.15104 664.576c-11.264 20.48-22.43584 39.56224-30.2592 49.152a15.0784 15.0784 0 0 1-11.02848 4.18816 15.96416 15.96416 0 0 1-16.01024-16.29184c0.03072-4.16256 1.53088-8.18688 4.23424-11.35616 9.40032-7.3984 29.83424-16.29184 53.06368-25.69216z"
        fill="#FFFFFF"
      />
      <path
        d="M930.06848 341.31968h-211.9168c-64.74752 0-123.20768-59.48928-123.20768-125.4912V0l335.12448 341.31968z"
        fill="#FFFFFF"
        fill-opacity=".4"
      />
    </svg>
  );
};
export default PdfIcon;

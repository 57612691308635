import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { MoodboardState } from "../../types/moodboardTypes";
import customFetch from "../../routes/util";
import { RootState } from "../../store/store";
import { colourPalettes, imageStyles } from "../../routes/moodboardData";

export const createMoodboard = createAsyncThunk(
  "moodboard/postMoodboardRequest",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { activeImageStyles, activeColourPalette, colourPalettes } =
      state.moodboard;
    const { analysisResult } = state.project;
    const longSynopsis = analysisResult.scriptSynopsis.paragraphs.join(" ");

    const payload = {
      scriptSynopsys: longSynopsis,
      imageStyles: activeImageStyles,
      colourPalette: colourPalettes[activeColourPalette]?.colours,
    };

    const response = await customFetch("/api/openai/moodboard", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {},
    });

    if (response.statusCode === 200) {
      return response.data;
    } else {
      return rejectWithValue(
        `Failed to create moodboard. statusCode: ${response.statusCode}, message: ${response.message}`
      );
    }
  }
);

const initialState: MoodboardState = {
  moodboardUrl: "",
  imageStyles: imageStyles,
  colourPalettes: colourPalettes,
  activeColourPalette: 0,
  activeImageStyles: [],
  isLoading: false,
  error: null,
};

export const moodboardSlice = createSlice({
  name: "moodboard",
  initialState,
  reducers: {
    changeColourPalleteOption: (state, action) => {
      state.activeColourPalette = action.payload;
    },
    toggleImageStyle: (state, action: PayloadAction<string>) => {
      const index = state.activeImageStyles.indexOf(action.payload);
      if (index >= 0) {
        state.activeImageStyles.splice(index, 1);
      } else {
        state.activeImageStyles.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMoodboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMoodboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.moodboardUrl = action.payload;
      })
      .addCase(createMoodboard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { changeColourPalleteOption, toggleImageStyle } =
  moodboardSlice.actions;
export default moodboardSlice.reducer;

import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MinimizeIcon from "../icons/MinimizeIcon";
import { toggleCharacterDialog } from "../../features/slices/projectSlice";
import { RootState } from "../../store/store";

const CharacterDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { analysisResult, activeCharacterForDialog } = useSelector(
    (state: RootState) => state.project
  );

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        dispatch(toggleCharacterDialog());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (activeCharacterForDialog === null) return null;

  const characters = analysisResult.characters;
  const activeCharacterData = characters.find(
    (char: any) => char.id === activeCharacterForDialog
  );

  if (!activeCharacterData) return null;

  const scenes = analysisResult.scenes;

  const getSceneNames = (sceneIds: number[]) => {
    return sceneIds.map((id) => {
      const scene = scenes.find((scene: any) => scene.id === id);
      return { id, name: scene?.name || "Unknown Scene" };
    });
  };

  const sceneNames = getSceneNames(activeCharacterData.scenes);

  return (
    <section ref={dialogRef} className="p-8 bg-white w-[80vw] max-w-[850px]">
      <header className="flex justify-between items-center">
        <h1 className="text-display-md font-medium text-gray-900">
          {activeCharacterData.name}
        </h1>
        <div
          className="cursor-pointer"
          onClick={() => {
            dispatch(toggleCharacterDialog());
          }}
        >
          <MinimizeIcon />
        </div>
      </header>
      <div className="">
        <div className="mt-8 flex space-x-8">
          <div className="flex-1">
            <h2 className="text-display-xs font-medium">Description</h2>
            <p className="pt-4 text-sm text-gray-700">
              {activeCharacterData.description}
            </p>
          </div>
          <div className="flex-1">
            <h2 className="text-display-xs font-medium">Characteristics</h2>
            <div className="flex flex-col items-start">
              {Object.entries(activeCharacterData.characteristics).map(
                ([key, value], index) => (
                  <div
                    className="mt-3 p-[2px] px-3 rounded-xl bg-gray-100 font-serif text-xs text-gray-700 font-medium"
                    key={index}
                  >
                    {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h2 className="text-display-xs font-medium">Scenes</h2>
          <div className="flex space-x-2 flex-wrap items-start">
            {sceneNames.map((scene: any, index: number) => (
              <div
                className="mt-3 p-[2px] px-3 rounded-xl bg-gray-100 font-serif text-xs text-gray-700 font-medium"
                key={index}
              >
                {scene.id}. {scene.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CharacterDialog;

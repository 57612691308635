import { ClimbingBoxLoader } from "react-spinners";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import RegenerateWhiteIcon from "../icons/RegenerateWhiteIcon";
import { toggleConfirmationDialog } from "../../features/slices/projectSlice";

const CostumePromptResults: React.FC = () => {
  const { activeCharacter, isLoading, costumeResults } = useSelector(
    (state: RootState) => state.costumes
  );
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const dispatch = useDispatch();

  const characters = analysisResult.characters;
  const activeCharacterData = characters[activeCharacter];

  const costumeResult = costumeResults.find(
    (result: any) => result.characterId === activeCharacterData.id
  );

  const handleRegenerate = () => {
    dispatch(toggleConfirmationDialog());
  };

  if (!costumeResult && !isLoading) {
    return null;
  }

  return (
    <div className="mt-10">
      <h1 className="text-md font-semibold text-gray-700">Result</h1>
      <div className="mt-2 rounded-xl border bg-gradient-to-bl from-gradient-0 to-gradient-1 p-[2px]">
        <div className="bg-white rounded-lg p-6 min-h-[500px]">
          {isLoading && (
            <div className="mt-[100px] flex flex-col justify-center items-center text-gray-500">
              <ClimbingBoxLoader color="#1e7b76" size={25} />
              <div className="mt-12 font-semibold text-xl">
                Generating costume...
              </div>
            </div>
          )}
          {!isLoading && costumeResult && (
            <>
              <div className="flex flex-col lg:flex-row gap-6">
                <img
                  className="object-contain w-[60%] h-[60%] align-top"
                  src={costumeResult.imageUrl}
                  alt=""
                />
                <div>
                  <h2 className="text-gray-900 text-sm font-semibold">Look</h2>
                  <p className="mt-4 text-gray-700 text-sm">
                    {costumeResult.outfitDescription}
                  </p>
                  <div className="mt-6 text-gray-900 text-sm font-semibold">
                    Colour Palette
                  </div>
                  <div className="mt-4 gap-2 flex flex-wrap text-xs font-medium text-gray-700">
                    {costumeResult.colorArray.map(
                      (color: any, index: number) => (
                        <div
                          key={index}
                          className="flex space-x-3 rounded-lg items-center border px-2 py-1"
                        >
                          <div
                            className="h-4 w-4 rounded-md"
                            style={{ backgroundColor: color.hex }}
                          ></div>
                          <div>
                            {color.name.charAt(0).toUpperCase() +
                              color.name.slice(1)}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <h1 className="text-md font-semibold text-gray-700">
                    Costume breakdown
                  </h1>
                  <button
                    onClick={handleRegenerate}
                    className="flex border items-center space-x-2 text-md font-semibold bg-primary-800 text-white  py-2 px-5 rounded-lg "
                  >
                    <div>
                      <RegenerateWhiteIcon />
                    </div>
                    <div>Regenerate</div>
                  </button>
                </div>
                <table className="mt-4 rounded-lg w-[100%]">
                  <thead className="bg-gray-300 text-gray-900 text-xs font-semibold">
                    <tr className="border-t border-l border-r">
                      <th className="w-[5%] px-5 py-3"></th>
                      <th className="w-[20%] px-5 py-3">Item</th>
                      <th className="px-5 py-3">Material</th>
                      <th className="w-[12%] px-5 py-3">Cost</th>
                      <th className="px-5 py-3">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {costumeResult.items.map((item: any, index: number) => (
                      <tr
                        key={index}
                        className="text-sm border-b border-l border-r"
                      >
                        <td className="text-center py-3 text-xs text-gray-700">
                          {index + 1}
                        </td>
                        <td className="px-5 py-3 border-r text-sm font-semibold text-gray-900">
                          <div className="flex space-x-4 shrink-0">
                            {item.name}
                          </div>
                        </td>
                        <td className="px-5 py-3 text-sm font-semibold text-center text-gray-900">
                          {item.material}
                        </td>
                        <td className="border-l border-r text-center py-3 text-sm font-semibold text-gray-900">
                          {item.cost} £
                        </td>
                        <td className="px-5 py-3">{item.description}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-center py-3 bg-gray-100 rounded-b-lg text-primary-800 text-sm font-bold">
                        {costumeResult.totalCost} &pound;
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
          {!isLoading && !costumeResult && (
            <div className="h-[500px] flex justify-center items-center text-gray-500">
              Click "Generate" to create a costume.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CostumePromptResults;

import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import UploadIcon from "../icons/UploadIcon";
import PdfIcon from "../icons/PdfIcon";
import DocIcon from "../icons/DocxIcon";
import TxtIcon from "../icons/TxtIcon";
import { UploadedFile } from "../../pages/NewProject";

interface FileUploadProps {
  uploadedFile: UploadedFile | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<UploadedFile | null>>;
  isUpLoading: boolean;
  setIsUpLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileUpload: React.FC<FileUploadProps> = ({
  uploadedFile,
  setUploadedFile,
  isUpLoading,
  setIsUpLoading,
  setIsFileUploaded,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      setIsUpLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!
              );
              setUploadProgress(progress);
            },
          }
        );

        if (response.status === 200) {
          setUploadedFile({
            name: file.name,
            size: (file.size / 1024).toFixed(2),
            type: file.type,
          });
          setIsFileUploaded(true);
          setIsUpLoading(false);
        } else {
          console.error("File upload failed.");
          setIsUpLoading(false);
          setIsFileUploaded(true);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUpLoading(false);
        setIsFileUploaded(true);
      }
    },
    [setUploadedFile, setIsUpLoading, setIsFileUploaded]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length > 0 && !uploadedFile) {
        const file = acceptedFiles[0];
        setUploadedFile({
          name: file.name,
          size: (file.size / 1024).toFixed(2), // Convert to KB
          type: file.type,
        });
        uploadFile(file);
      }
    },
    [uploadedFile, setUploadedFile, uploadFile]
  );

  const removeFile = () => {
    setUploadedFile(null);
    setUploadProgress(0);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    maxSize: 30 * 1024 * 1024, // 30MB
    noClick: !!uploadedFile,
    noDrag: !!uploadedFile,
  });

  const renderFileIcon = (type: string) => {
    switch (type) {
      case "application/pdf":
        return <PdfIcon />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <DocIcon />;
      case "text/plain":
        return <TxtIcon />;
      default:
        return <UploadIcon />;
    }
  };

  return (
    <div className="relative w-full h-[250px] flex items-center justify-center outline outline-[12px] rounded-sm outline-gray-200">
      <div
        {...getRootProps()}
        className={`w-full h-full hover:bg-[#F9FAFB] cursor-pointer flex items-center justify-center ${
          isDragActive ? "bg-gray-100" : ""
        } ${uploadedFile || isUpLoading ? "hidden" : ""}`}
      >
        <input {...getInputProps()} />
        {!uploadedFile && !isUpLoading && (
          <div className="flex flex-col items-center w-full text-center">
            <div className="bg-gray-100 rounded-full p-2 outline outline-8 outline-[#F9FAFB]">
              <UploadIcon />
            </div>
            <div className="mt-4 text-sm">
              <span className="text-primary-800 font-semibold mr-1">
                Click to upload
              </span>
              or drag and drop
            </div>
            <div className="mt-1 text-[10px] text-gray-600">
              DOCX, TXT or PDF (max. 30MB)
            </div>
          </div>
        )}
      </div>

      {(uploadedFile || isUpLoading) && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center  bg-opacity-90 p-4 rounded-lg border border-gray-300 max-w-lg w-full">
          <div className="flex items-center gap-4 w-full">
            {uploadedFile && renderFileIcon(uploadedFile.type)}
            <div className="text-gray-800 font-medium text-md truncate flex-grow">
              {uploadedFile?.name}
            </div>
            <div className="text-gray-500 text-sm">{uploadedFile?.size} KB</div>
            <button
              className="text-red-600 hover:text-red-800 text-lg"
              onClick={removeFile}
            >
              &times;
            </button>
          </div>
          {isUpLoading && (
            <>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-1">
                <div
                  className="bg-blue-600 h-2 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <div className="w-full flex justify-between text-sm text-gray-700">
                <span>{uploadProgress}% done</span>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;

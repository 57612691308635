import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/slices/navigationSlice";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../store/store";
import { ClimbingBoxLoader } from "react-spinners";

const Login: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, isAuthenticated, error } = useSelector(
    (state: RootState) => state.navigation
  );

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const hasNavigated = useRef(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      hasNavigated.current = true;
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="relative w-full h-screen">
      <nav className="absolute top-0 left-0 w-full py-4">
        <div className="mx-auto w-[90%] max-w-screen-3xl flex justify-start">
          <div className="flex space-x-2 items-center">
            <img className="w-8 h-8" src={Logo} alt="Logo" />
            <div className="text-display-xs font-medium">FilmTailor AI</div>
          </div>
        </div>
      </nav>
      <div className="mx-auto w-[90vw] flex items-center justify-center h-full">
        {isLoading ? (
          <div className="h-[700px] flex flex-col justify-center items-center text-gray-500">
            <ClimbingBoxLoader color="#1e7b76" size={25} />
          </div>
        ) : (
          <div>
            <h1 className="text-display-md font-semibold">Welcome back!</h1>
            <p className="mt-2 font-medium">
              Enter your Credentials to access your account
            </p>

            <form
              className="mt-8 max-w-[425px]"
              onSubmit={handleLogin}
              action=""
            >
              {error && (
                <div className="text-center text-[#f34343] bg-[#fff1f0] p-2 rounded-lg my-2">
                  <p>{error}</p>
                </div>
              )}
              <label className="text-sm font-semibold" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="my-2 mb-4 w-full py-2 px-4 border border-gray-300 rounded-lg outline-none"
              />
              <label className="text-sm font-semibold" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className="mt-2 w-full mb-6 py-2 px-4 border border-gray-300 rounded-lg outline-none"
              />
              <button
                type="submit"
                className="w-full py-2 rounded-lg bg-primary-800 text-white  mb-4"
              >
                Login
              </button>
            </form>
            {/* <p className="text-[9px] font-bold text-center">Or</p>
          <div className="mt-4 flex justify-center space-x-4">
            <button className="flex items-center border px-4 py-2 rounded-lg text-sm font-semibold">
              <GoogleLogo />
              <div className="ml-2">Sign in with Google</div>
            </button>
            <button className="flex items-center border px-4 py-2 rounded-lg text-sm font-semibold">
              <AppleLogo />
              <div className="ml-2">Sign in with Apple</div>
            </button>
          </div>
          
          <div className="mt-6 text-center text-sm font-medium">
            <div>Don't have an account?</div>
            <button className="mt-2 text-[#0F3DDE]">Sign Up</button>
          </div> */}
            <div className="text-center">
              Sign up is not available at the moment.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;

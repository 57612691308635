import RegenerateIcon from "../icons/RegenerateIcon";

const CostumeControls = () => {
  return (
    <div className="mt-6 flex space-x-4">
      <button className="font-semibold bg-primary-800 py-2 px-5 rounded-lg text-white shrink-0">
        Save costume
      </button>
      <button className="flex border items-center space-x-2 text-md font-semibold border-gray-300 py-2 px-5 rounded-lg hover:bg-gray-300">
        <RegenerateIcon />
        <div>Regenerate</div>
      </button>
    </div>
  );
};
export default CostumeControls;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCharacter } from "../../features/slices/costumeSlice";
import RightChevronIcon from "../icons/RightChevronIcon";
import { RootState } from "../../store/store";

const CostumesMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { activeCharacter } = useSelector((state: RootState) => state.costumes);
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const characters = analysisResult.characters;

  return (
    <div className="mt-[6px] mb-10 2xl:w-[210px] h-full mr-10">
      <h1 className="text-lg font-semibold text-gray-900">Characters</h1>
      <div className="mt-10 rounded-xl 2xl:border max-w-[800px]">
        <div className="hidden 2xl:block text-center rounded-t-xl py-4 font-semibold text-xs text-gray-900 bg-gray-300  border-b ">
          Character name
        </div>
        <div className="flex flex-wrap 2xl:flex-col 2xl:divide-x 2xl:divide-y">
          {characters.map((character: any, index: number) => (
            <div
              key={index}
              className={`py-4 px-4 lg:px-6 border 2xl:border-none m-[2px] 2xl:m-[0] rounded-lg 2xl:rounded-none hover:cursor-pointer text-sm text-gray-700 ${
                activeCharacter === index
                  ? "bg-gray-100 font-semibold flex justify-between"
                  : ""
              }`}
              onClick={() => dispatch(setActiveCharacter(index))}
              style={{ flexBasis: "auto", minWidth: 0 }}
            >
              <span className="flex-auto">{character.name}</span>
              <div className="hidden 2xl:block">
                {activeCharacter === index && <RightChevronIcon />}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CostumesMenu;

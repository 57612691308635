import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CostumeState, Item } from "../../types/costumeTypes";
import { RootState } from "../../store/store";
import customFetch from "../../routes/util";

export const createCostume = createAsyncThunk(
  "costume/createCostume",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { analysisResult } = state.project;
    const { activeCharacter, promptContent } = state.costumes;

    const activeCharacterData = analysisResult.characters[activeCharacter];
    const characterPrompt = promptContent[activeCharacter] || "";

    const payload = {
      characteristics: activeCharacterData.characteristics,
      characterDescription: activeCharacterData.description,
      costumePrompt: characterPrompt,
    };

    const response = await customFetch("/api/openai/costume", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {},
    });

    if (response.statusCode === 200) {
      const items = response.data.items || [];
      const totalCost = items.reduce(
        (acc: any, item: any) => acc + parseFloat(item.cost || 0),
        0
      );

      const uniqueColors = new Map();
      items.forEach((item: any) => {
        if (!uniqueColors.has(item.color.hex)) {
          uniqueColors.set(item.color.hex, item.color.name);
        }
      });

      const colorArray = Array.from(uniqueColors, ([hex, name]) => ({
        hex,
        name,
      }));

      return {
        ...response.data,
        totalCost,
        colorArray,
        characterId: activeCharacterData.id,
      };
    } else {
      return rejectWithValue(
        `Failed to create costume. StatusCode: ${response.statusCode}, Message: ${response.message}`
      );
    }
  }
);

const initialState: CostumeState = {
  activeCostumeOption: 0,
  activeCharacter: 0,
  isCharacterDialogVisible: false,
  isItemDialogVisible: false,
  selectedItem: null,
  promptContent: {},
  costumeDescriptionResult: null,
  error: null,
  costumeResults: [],
  isLoading: false,
};

export const costumeSlice = createSlice({
  name: "costumes",
  initialState,
  reducers: {
    setActiveCharacter(state, action: PayloadAction<number>) {
      state.activeCharacter = action.payload;
    },
    setActiveCostumeOption(state, action: PayloadAction<number>) {
      state.activeCostumeOption = action.payload;
    },
    toggleCharacterDialog(state) {
      state.isCharacterDialogVisible = !state.isCharacterDialogVisible;
    },
    toggleItemDialog(state, action: PayloadAction<Item | undefined>) {
      state.isItemDialogVisible = !state.isItemDialogVisible;
      state.selectedItem = action.payload !== undefined ? action.payload : null;
    },
    setPromptContent(
      state,
      action: PayloadAction<{ characterId: number; prompt: string }>
    ) {
      const { characterId, prompt } = action.payload;
      state.promptContent[characterId] = prompt;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCostume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCostume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.costumeDescriptionResult = action.payload;
        const index = state.costumeResults.findIndex(
          (result: any) => result.characterId === action.payload.characterId
        );
        if (index !== -1) {
          state.costumeResults[index] = action.payload;
        } else {
          state.costumeResults.push(action.payload);
        }
      })
      .addCase(createCostume.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message || "Failed to generate costume description";
      });
  },
});

export const {
  setActiveCharacter,
  setActiveCostumeOption,
  toggleCharacterDialog,
  toggleItemDialog,
  setPromptContent,
} = costumeSlice.actions;
export default costumeSlice.reducer;

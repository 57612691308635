import React from "react";
import { useLocation } from "react-router-dom";
import stepsData from "../routes/stepsData";
import RightChevronLightIcon from "../components/icons/RightChevronLightIcon";

const StepperNavLayout: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const renderSteps = () => {
    const currentStepIndex = stepsData.findIndex((step) =>
      currentPath.startsWith(step.path)
    );
    if (currentStepIndex === -1) return null;

    return (
      <>
        <span className="text-gray-600 font-medium text-sm">Projects</span>
        {stepsData.slice(0, currentStepIndex + 1).map((step, index) => (
          <React.Fragment key={step.path}>
            <span className="mx-2 p-2 text-gray-400">
              <RightChevronLightIcon />
            </span>
            <span className="text-gray-700 font-semibold text-sm">
              {step.label}
            </span>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <section className="py-4 border-b mb-10">
      <div className="mx-auto w-[90%] max-w-screen-3xl">
        <div className="flex items-center text-sm font-medium">
          {renderSteps()}
        </div>
      </div>
    </section>
  );
};

export default StepperNavLayout;

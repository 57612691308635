const CloseIconLight = () => {
  return (
    <>
      <svg
        id="icon"
        className="cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          id="icon-path"
          d="M17 7L7 17M7 7L17 17"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <style>
        {`
          #icon:hover path {
            stroke: #344054; 
          }
        `}
      </style>
    </>
  );
};
export default CloseIconLight;

import Cookies from "js-cookie";

interface CustomFetchOptions extends RequestInit {
  headers: HeadersInit;
}

const BASE_URL = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");

async function customFetch(endpoint: string, options?: CustomFetchOptions) {
  const url = `${BASE_URL}${endpoint}`;
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const finalOptions: CustomFetchOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options?.headers,
      credentials: "include",
    },
  };

  const apiResponse = await fetch(url, finalOptions);
  return apiResponse.json();
}

export default customFetch;

import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MinimizeIcon from "../icons/MinimizeIcon";
import { toggleSceneDialog } from "../../features/slices/projectSlice";
import { RootState } from "../../store/store";

const SceneDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { activeScene } = useSelector((state: RootState) => state.project);
  const { analysisResult } = useSelector((state: RootState) => state.project);

  const scenes = analysisResult.scenes;
  const characters = analysisResult.characters;
  const activeSceneData = scenes.find((scene: any) => scene.id === activeScene);

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        dispatch(toggleSceneDialog());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getCharactersInScene = (sceneId: number) => {
    return characters.filter((character: any) =>
      character.scenes.includes(sceneId)
    );
  };

  const charactersInScene = getCharactersInScene(activeScene);

  return (
    <section ref={dialogRef} className="p-8 bg-white w-[80vw] max-w-[850px]">
      <header className="flex justify-between items-center">
        <h1 className="text-display-md font-medium text-gray-900">
          {activeSceneData.id}. {activeSceneData.name}
        </h1>
        <div
          className="cursor-pointer"
          onClick={() => dispatch(toggleSceneDialog())}
        >
          <MinimizeIcon />
        </div>
      </header>
      <div className="flex mt-6">
        <div className="mt-4 flex-1">
          <h2 className="text-display-xs font-medium">Description</h2>
          <p className="pt-4 text-sm text-gray-700">
            {activeSceneData.description}
          </p>
        </div>
        <div className="mt-4 ml-12 flex-1">
          <h2 className="text-display-xs font-medium">Characters</h2>
          <div className="flex flex-wrap space-x-3 items-start">
            {charactersInScene.map((character: any, index: number) => (
              <div
                className="mt-3 p-[2px] px-3 rounded-xl bg-gray-100 font-serif text-sm text-gray-700 font-medium"
                key={index}
              >
                {character.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SceneDialog;
